.product-nav-wrapper {
    display: flex;
    align-items: center;

    .product-filter-nav {
        list-style: none;
        margin-bottom: 30px;

        li {
            display: inline-block;

            a {
                display: inline-block;
                font-size: 16px;
                font-weight: 500;
                margin-right: 20px;
                color: $body-color;
            }

            &.active {
                a {
                    color: theme-color(primary);
                }
            }
        }
    }

    .product-filter-options {
        margin-left: auto;
        padding-left: 0;
        display: flex;
        align-items: center;

        .account-user-info {
            list-style: none;
            margin-bottom: 30px;

            li {
                display: inline-block;

                a {
                    display: inline-block;
                    font-size: 16px;
                    margin-right: 10px;
                    padding-right: 10px;
                    line-height: 1;
                    color: $body-color;
                    border-right: 1px solid $border-color;
                }

                &:last-child {
                    a {
                        border-right: none;
                    }
                }
            }
        }

        .account-user-info {
            list-style: none;
            margin-bottom: 30px;

            li {
                display: inline-block;

                a {
                    display: inline-block;
                    font-size: 16px;
                    margin-right: 10px;
                    padding-right: 10px;
                    line-height: 1;
                    color: $body-color;
                    border-right: 1px solid $border-color;
                    text-decoration: none;
                }

                &:last-child {
                    a {
                        border-right: none;
                    }
                }
            }
        }

        .account-user-link {
            list-style: none;
            margin-bottom: 30px;

            li {
                display: inline-block;

                a {
                    display: inline-block;
                    font-size: 16px;
                    line-height: 1;
                    color: $body-color;
                    text-decoration: none;

                    &:after {
                        content: "/";
                        display: inline-block;
                        margin-left: 5px;
                        margin-right: 5px;
                    }
                }

                &:last-child {
                    a {
                        &:after {
                            display: none;
                            margin-left: 0px;
                        }
                    }
                }
            }
        }

        .account-user-actions {
            list-style: none;
            margin-bottom: 30px;

            li {
                display: inline-block;
                margin-right: 15px;
                position: relative;

                .badge {
                    position: absolute;
                    top: -12px;
                    right: -7px;
                }

                a {
                    display: inline-block;
                    font-size: 16px;
                    line-height: 1;
                    color: $body-color;
                    text-decoration: none;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

.product-item-wrapper {
    margin-bottom: -15px;

    .product-item {
        box-shadow: none;
        margin-bottom: 20px;

        .card {
            box-shadow: none;
        }

        .card-body {
            padding: 20px 25px;
            display: grid;
            position: relative;
            grid-template-columns: 70% 30%;
            grid-template-rows: auto;
            grid-template-areas:
                "product-image product-image"
                "product-title product-price"
                "product-variation product-actual-price"
                "product-description product-description";

            .action-holder {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                padding: 35px 40px;
                display: flex;
                align-items: center;
                z-index: 2;

                .sale-badge {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    line-height: 1;
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;
                    color: #fff;
                    font-size: 12px;
                    font-weight: 500;
                }

                .favorite-button {
                    margin-left: auto;
                    color: #fff;
                    font-size: 22px;
                }
            }

            .product_image {
                width: 100%;
                max-width: 100%;
                min-width: 100%;
                grid-area: product-image;
                margin-bottom: 15px;
                transform: scale(1);
                z-index: 1;
                border-radius: 0px;
                box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0);
                transition-duration: 0.3s;
                transition-timing-function: "ease";
                transition-property: "transform", "border-radius", "box-shadow";
            }

            .product-title {
                grid-area: product-title;
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 10px;
            }

            .product-price {
                grid-area: product-price;
                font-size: 20px;
                font-weight: 500;
                text-align: right;
                margin-bottom: 10px;
            }

            .product-actual-price {
                grid-area: product-actual-price;
                font-size: 15px;
                color: $text-gray;
                text-align: right;
                text-decoration: line-through;
            }

            .product-variation {
                grid-area: product-variation;
                list-style-type: none;
                display: inline-block;
                padding-left: 0;
                padding-bottom: 0;

                li {
                    display: inline-block;
                    border: 1px solid $border-color;
                    padding: 0px 15px;
                    border-radius: 4px;
                    font-size: 12px;
                    font-weight: 500;
                    transition-duration: 0.3s;
                    transition-property: "background";

                    a {
                        color: inherit;
                        text-decoration: none;
                    }

                    &:hover {
                        background: theme-color(secondary);
                    }
                }
            }

            .product-description {
                grid-area: product-description;
                line-height: 1.71;
            }
        }

        &:hover {
            .product_image {
                transform: scale(1.1);
                border-radius: 4px;
                box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.58);
            }
        }
    }
}